import ContributionModal from '@/components/modal/ContributionModal.vue'
import RewardModal from '@/components/modal/RewardModal.vue'
import ContributionSidebar from '@/components/modal/sidebar/ContributionSidebar.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'
import type { ContributionType, RewardModalProps, Tab } from '~/types'

const resetContributionModal = () => {
  contributionModal.patchOptions(getContributionModalConfig())
}

const onOpenRewardModal = (rewardProps: RewardModalProps) => {
  contributionModal.patchOptions({
    attrs: {
      modalId: 'reward',
      title: rewardProps.title,
    },
    slots: {
      default: useModalSlot({
        component: RewardModal,
        attrs: {
          data: rewardProps,
          onOpenContributedModal: resetContributionModal,
        },
      }),
    },
  })
}

export type SidebarVersion = 'contribute' | 'review' | 'points'

const getContributionModalConfig = (tab: Tab = 'source' as const) => {
  const contributionType = ref<ContributionType>(tab)
  const sidebarVersion = ref<SidebarVersion>()
  return {
    attrs: {
      modalId: 'contribution',
      title: 'Ajouter un contenu',
      clickToClose: false,
      onClosed: resetContributionModal,
    },
    slots: {
      default: useModalSlot({
        component: ContributionModal,
        attrs: {
          onOpenRewardModal,
          tab,
          onUpdateContributionType: (type: ContributionType) => {
            contributionType.value = type
          },
          onUpdateSidebar: (version: SidebarVersion) => {
            sidebarVersion.value = version
          },
        },
      }),
      sidebar: useModalSlot({
        component: ContributionSidebar,
        attrs: {
          version: sidebarVersion as unknown as Tab,
          contributionType: contributionType as unknown as ContributionType,
        },
      }),
    },
  }
}

export const setContributionModalTab = (tab: Tab) => {
  contributionModal.patchOptions(getContributionModalConfig(tab))
}

export const contributionModal = useCustomModal(getContributionModalConfig())
