<template>
  <div
    class="sidebar"
    style="color: #fff"
  >
    <div
      v-if="version === 'contribute'"
      class="sidebar__layout sidebar__layout--contribute"
    >
      <p class="title">
        Quelques conseils pour la rédaction de votre contenu :
      </p>
      <p>Rédigez un texte de 140 caractères de préférence.</p>
      <p>Agrémentez-le d’une image et éventuellement d’une vidéo Youtube.</p>
      <p>Attention aux fautes d’orthographe 😉</p>
    </div>
    <div
      v-else-if="version === 'review'"
      class="sidebar__layout sidebar__layout--review"
    >
      <p class="title">Qu’est ce qu’un commentaire intéressant ?</p>
      <p>
        <strong>
          Soyez précis et donnez des détails. Qu’auriez-vous aimé savoir avant
          d’acheter ce produit ?
        </strong>
      </p>
      <p>Votre titre doit faire 50 caractères maximum.</p>
      <p>
        Un avis ni trop court ni trop long (utilisez 140 caractères minimum).
      </p>
      <p>
        Pour que votre avis soit publié, vous devez respecter les consignes
        suivantes : - Votre avis doit se limiter au produit et à votre
        expérience du produit. - Ne mentionnez pas la concurrence ni le prix
        d’achat de votre produit.
      </p>
      <p>
        Tous les avis soumis sont assujettis aux conditions générales prévues
        dans nos conditions générales d’utilisation disponible sur le site
        weareplaystation.com.
      </p>
      <p>
        Sony Interactive Entertainment France est en partenariat avec une
        société d’avis indépendante, qui recueille, modère et publie ces avis
        sur le site weareplaystation.com . Les avis ne sont pas modifiés. Nous
        sommes transparents : nous affichons les bons et mauvais commentaires
        (tant qu’ils respectent les consignes rédactionnelles). Nos avis sont
        impartiaux et indépendants.
      </p>
    </div>
    <div
      v-else
      class="sidebar__layout sidebar__layout--default"
    >
      <p>
        Publier un nouveau contenu et gagner
        <br />
        <span>{{ points }} {{ pluralize('pt', points) }}</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContributionType } from '~/types'

const props = withDefaults(
  defineProps<{
    contributionType: ContributionType
    version?: string
  }>(),
  {
    version: 'default',
  },
)

const referentialStore = useReferentialStore()
const { referential } = storeToRefs(referentialStore)

const points = computed(() => {
  const { points } = referential.value ?? {}

  if (props.contributionType === 'source') {
    return points?.post_source_create
  }

  if (props.contributionType === 'contribute') {
    return points?.post_contribute_create
  }

  return points?.post_review_create
})
</script>

<style lang="scss" scoped>
.sidebar {
  @media (min-width: 992px) {
    flex: 1 1 auto;
    max-width: 300px;
  }

  &__layout {
    padding: 2rem;
    color: #fff;

    @media (min-width: 992px) {
      padding: 5rem;
    }

    p {
      line-height: 1.7;
    }

    &--default {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: #011132;
      background-image: url('assets/img/contribution-sidebar.jpg');
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;

      span {
        font-size: 3rem;
        line-height: 1.2;

        @media (min-width: 992px) {
          font-size: 4.2rem;
        }
      }
    }

    &--contribute,
    &--review {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: $blue;

      .title {
        font-size: 2.2rem;
        line-height: 1.2;
      }

      p {
        margin-bottom: 2rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--contribute {
      justify-content: center;
    }

    &--review {
      justify-content: flex-start;
      overflow-y: scroll;
    }
  }
}
</style>
