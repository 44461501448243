<template>
  <div class="contrib">
    <div class="header">
      <p
        :class="{ 'is-active': selectedTab === 'source' }"
        class="header__tab"
        @click="changeTab('source')"
      >
        Contenu provenant d'un autre site
      </p>
      <p
        class="header__tab"
        :class="{ 'is-active': selectedTab === 'contribute' }"
        @click="changeTab('contribute')"
      >
        Créer mon contenu perso
      </p>
    </div>
    <div
      class="hr"
      :class="{ 'is-loading': loading }"
    />
    <div
      v-if="initialized"
      class="fillSpace"
    >
      <div
        v-if="loading"
        class="backdrop"
      />
      <Component
        :is="currentFormComponent"
        :defaults="defaults"
        class="fillSpace"
        @end-submit="nextModal"
        @change-sidebar="changeSidebar"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  Defaults,
  Endpoints,
  ModalOptions,
  RewardModalProps,
} from '~/types'
import SourcedContentForm from '@/components/features/Contribution/SourcedContentForm.vue'
import ContributedContentForm from '@/components/features/Contribution/ContributedContentForm.vue'
import type { SidebarVersion } from '~/modals/contribution'

const props = withDefaults(
  defineProps<{
    tab?: 'review' | 'source' | 'contribute'
  }>(),
  {
    tab: 'review',
  },
)

type Tab = 'source' | 'contribute'

const emit = defineEmits<{
  updateSidebar: [version: SidebarVersion]
  updateContributionType: [type: 'contribute' | 'source']

  // Should patch modal with data as props
  openRewardModal: [modalConfig: RewardModalProps]
}>()

const route = useRoute()

const selectedTab = ref<Tab>('source')
const review = ref(false)
const loading = ref(false)
const initialized = ref(false)

const defaults = ref<Defaults>({})

if (props.tab === 'review') {
  review.value = true
  selectedTab.value = 'contribute'
}

const initData = async (tab: 'source' | 'contribute' | 'review') => {
  defaults.value = {}

  if (!route.path.includes('/communautes/')) {
    return
  }

  const path = route.path.replace('/communautes/', '')
  const params = path.split('/')

  const [communityPath, categoryPath, subcategoryPath] = params

  if (!communityPath) {
    return
  }

  loading.value = true

  const { data, error } = await useCustomFetch<
    Endpoints['GET__COMMUNITY_POST_TYPES']
  >(API.GET__COMMUNITY_POST_TYPES, {
    query: {
      post_type: tab,
      slug: communityPath,
    },
  })

  const rawData = unref(data)
  if (error.value || !rawData) {
    loading.value = false
    return logError(
      'from contribution modal',
      'There was a problem getting post types: ',
      error,
    )
  }

  defaults.value.community = rawData[0]

  if (categoryPath) {
    const category = rawData[0].content_blocks?.find(
      (item) => item.content_block_type?.category?.slug === categoryPath,
    )?.content_block_type?.category

    if (!category) {
      loading.value = false
      return
    }

    defaults.value = {
      ...defaults.value,
      category,
    }

    if (!subcategoryPath) {
      loading.value = false
      return
    }

    const subcategory = category?.children?.find(
      (item) => item.slug === subcategoryPath,
    )

    if (!subcategory) {
      loading.value = false
      return
    }

    defaults.value = {
      ...defaults.value,
      subcategory,
    }
  } else if (review.value) {
    const category = rawData[0].content_blocks?.find(
      (item) => item.content_block_type?.category?.is_review,
    )?.content_block_type?.category

    if (category) {
      defaults.value = {
        ...defaults.value,
        category,
      }
    }
  }

  loading.value = false
}

emit('updateSidebar', 'points')

await initData(selectedTab.value)
initialized.value = true

const currentFormComponent = computed(() => {
  return selectedTab.value === 'source'
    ? SourcedContentForm
    : ContributedContentForm
})

const changeTab = async (tab: 'source' | 'contribute') => {
  await initData(tab)
  emit('updateContributionType', tab)
  emit('updateSidebar', 'points')
  selectedTab.value = tab
}

const nextModal = (data: ModalOptions) => {
  return emit('openRewardModal', data)
}

const { isMobile, isLargeMobile, isTabletPortrait } = useCustomMediaQuery()

const changeSidebar = (version: SidebarVersion) => {
  if (isMobile.value || isLargeMobile.value || isTabletPortrait.value) {
    return
  }

  emit('updateSidebar', version)
}
</script>

<style lang="scss" scoped>
.hr {
  position: relative;
  flex-shrink: 0;
  height: 1px;
  margin: 1.5rem 0;
  overflow: hidden;
  background-color: rgba(0 0 0 / 0.15);

  &.is-loading {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25%;
      display: block;
      width: 25%;
      height: 100%;
      background-color: $blue;
      animation: loading 0.7s infinite linear;
    }
  }
}

.contrib {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  &__tab {
    cursor: pointer;
    opacity: 0.3;

    &.is-active {
      color: $challengeBackgroundBlue;
      opacity: 1;
    }
  }
}

.fillSpace {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.backdrop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@keyframes loading {
  0% {
    left: -25%;
  }

  90%,
  100% {
    left: 100%;
  }
}
</style>
