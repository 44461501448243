<template>
  <div class="c-range">
    <div class="c-range__container">
      <template
        v-for="index in counter"
        :key="`input${index}`"
      >
        <input
          :id="`${name}-${index}`"
          v-model="inputValue"
          :value="index"
          :name="name"
          :required="required"
          type="radio"
          class="c-range__input"
        />
        <label
          :for="`${name}-${index}`"
          class="c-range__item"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    modelValue?: number
    required?: boolean
  }>(),
  {
    modelValue: 0,
    required: false,
  },
)
const emit = defineEmits(['update:modelValue'])

const counter = ref([5, 4, 3, 2, 1])

const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>

<style lang="scss" scoped>
.c-range {
  $root: &;

  width: 20rem;
  height: 2rem;
  overflow: hidden;
  background-image: linear-gradient(to right, $blue 0%, #15a3d8 100%);
  border-radius: 2rem;

  @media (min-width: 992px) {
    width: 25rem;
  }

  &:hover {
    #{$root}__item:not(:hover) {
      background-color: #dedede;
    }

    #{$root}__item:hover {
      &,
      ~ #{$root}__item {
        background-color: transparent;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
  }

  &__input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:checked {
      ~ #{$root}__item {
        background-color: transparent;
      }
    }
  }

  &__item {
    display: block;
    flex: 1 0 auto;
    height: 100%;
    background-color: #dedede;
    border-right: 1px solid $white;

    &:first-of-type {
      border-right: 0;
    }
  }
}
</style>
