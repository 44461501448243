<template>
  <fieldset
    class="c-rating"
    name="rating"
  >
    <div class="u-d-f u-ai-c u-fd-rr">
      <template
        v-for="index in counter"
        :key="`input${index}`"
      >
        <input
          :id="`star${index}`"
          v-model="inputValue"
          :value="index"
          type="radio"
          name="rating"
          class="c-rating__input"
          required
        />
        <label
          :for="`star${index}`"
          class="c-rating__item"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.432 15.879"
          >
            <path
              fill="#363636"
              fill-rule="evenodd"
              d="M8.346 0a.5.5 0 00-.056 0 .548.548 0 00-.455.296L5.504 4.829l-5.026.633a.549.549 0 00-.474.613.543.543 0 00.151.313l3.56 3.639-.956 5.008a.549.549 0 00.433.644.559.559 0 00.352-.05l4.527-2.283 4.44 2.461a.545.545 0 00.8-.557l-.756-5.055 3.7-3.488a.549.549 0 00.029-.774.535.535 0 00-.317-.168l-4.992-.83L8.822.317A.55.55 0 008.346 0z"
            />
          </svg>
        </label>
      </template>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue?: number
  }>(),
  {
    modelValue: 0,
  },
)
const emit = defineEmits(['update:modelValue'])

const counter = ref([5, 4, 3, 2, 1])

const inputValue = computed<number>({
  get: () => props.modelValue ?? 0,
  set: (value) => emit('update:modelValue', value),
})
</script>

<style lang="scss">
.c-rating {
  $root: &;

  padding: 0;
  border: 0;

  &:hover {
    #{$root}__item:not(:hover) {
      svg path {
        fill: #dedede;
      }
    }

    #{$root}__item:hover {
      svg path,
      ~ #{$root}__item svg path {
        fill: $orange;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 0.4rem;

    &:first-of-type {
      padding-right: 0;
    }

    &:last-of-type {
      padding-left: 0;
    }

    svg {
      width: 2rem;
      height: 2rem;

      path {
        fill: #dedede;
      }
    }
  }

  &__input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:checked {
      ~ #{$root}__item svg path {
        fill: $orange;
      }
    }
  }
}
</style>
